import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { License } from '../types/license';
import { Plugin } from '../types/plugin';
import { useAuth } from '../contexts/AuthContext';

interface AddLicenseDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (licenseData: Omit<License, "id">) => Promise<void>;
}

const AddLicenseDialog: React.FC<AddLicenseDialogProps> = ({ open, onClose, onSuccess }) => {
  const [plugins, setPlugins] = useState<Plugin[]>([]);
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({
    pluginId: '',
    pluginName: '',
    domain: '',
    isValid: true,
    expiresAt: '' as string,
  });

  useEffect(() => {
    const fetchPlugins = async () => {
      try {
        const pluginsRef = collection(db, 'plugins');
        const snapshot = await getDocs(pluginsRef);
        const pluginsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Plugin[];
        setPlugins(pluginsData);
      } catch (error) {
        console.error('Error fetching plugins:', error);
      }
    };

    fetchPlugins();
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    const selectedPlugin = plugins.find(p => p.id === formData.pluginId);
    if (!selectedPlugin) return;

    const licenseData: Omit<License, 'id'> = {
      pluginId: formData.pluginId,
      pluginName: selectedPlugin.name,
      domain: formData.domain,
      licenseKey: crypto.randomUUID(),
      isValid: true,
      expiresAt: formData.expiresAt || '',
      createdAt: new Date().toISOString(),
      userId: currentUser?.uid || '',
      userEmail: currentUser?.email || '',
    };

    onSuccess(licenseData);
    setFormData({
      pluginId: '',
      pluginName: '',
      domain: '',
      isValid: true,
      expiresAt: '',
    });
    onClose();
  };

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4">
        <div className="fixed inset-0 bg-black bg-opacity-25" onClick={onClose} />
        
        <div className="relative bg-white rounded-lg shadow-xl max-w-md w-full">
          <div className="px-6 py-4 border-b">
            <h2 className="text-xl font-semibold text-gray-900">Új Licensz létrehozása</h2>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="px-6 py-4 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Plugin
                </label>
                <select
                  value={formData.pluginId}
                  onChange={(e) => setFormData({ 
                    ...formData, 
                    pluginId: e.target.value as string,
                  })}
                  required
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="">Válassz plugin-t</option>
                  {plugins.map((plugin) => (
                    <option key={plugin.id} value={plugin.id}>
                      {plugin.name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Domain
                </label>
                <input
                  type="text"
                  value={formData.domain}
                  onChange={(e) => setFormData({ ...formData, domain: e.target.value })}
                  required
                  placeholder="pl: example.com"
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Lejárat dátuma
                </label>
                <input
                  type="date"
                  value={formData.expiresAt ? formData.expiresAt.split('T')[0] : ''}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    expiresAt: e.target.value || ''
                  }))}
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
            </div>

            <div className="px-6 py-4 border-t bg-gray-50 flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 rounded-md text-gray-700 hover:bg-gray-100"
              >
                Mégse
              </button>
              <button
                type="submit"
                className="px-4 py-2 rounded-md bg-indigo-600 text-white hover:bg-indigo-700"
              >
                Létrehozás
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddLicenseDialog; 