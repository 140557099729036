import React, { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Navbar from './Navbar';
import { db } from '../firebaseConfig';
import { collection, getDocs, query, orderBy, deleteDoc, doc, addDoc, updateDoc } from 'firebase/firestore';
import { License } from '../types/license';
import AddLicenseDialog from './AddLicenseDialog';
import SearchIcon from '@mui/icons-material/Search';
import EditLicenseDialog from './EditLicenseDialog';

const generateLicenseKey = (): string => {
  return crypto.randomUUID();
};

const LicenseManager: React.FC = () => {
  const [licenses, setLicenses] = useState<License[]>([]);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState<License | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchLicenses = async () => {
      try {
        const licensesRef = collection(db, 'licenses');
        const q = query(licensesRef, orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);
        
        const licensesData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as License[];
        
        setLicenses(licensesData);
      } catch (error) {
        console.error('Error fetching licenses:', error);
      }
    };

    fetchLicenses();
  }, []);

  const handleDeleteLicense = async (id: string) => {
    if (!window.confirm('Biztosan törölni szeretné ezt a licenszt?')) return;

    try {
      await deleteDoc(doc(db, 'licenses', id));
      setLicenses(licenses.filter(license => license.id !== id));
    } catch (error) {
      console.error('Error deleting license:', error);
    }
  };

  const handleAddLicense = async (licenseData: Omit<License, 'id'>) => {
    try {
      const docRef = await addDoc(collection(db, 'licenses'), licenseData);
      setLicenses([...licenses, { id: docRef.id, ...licenseData }]);
      setIsAddDialogOpen(false);
    } catch (error) {
      console.error('Error adding license:', error);
    }
  };

  const handleEditLicense = async (id: string, licenseData: Partial<License>) => {
    try {
      await updateDoc(doc(db, 'licenses', id), {
        ...licenseData,
        updatedAt: new Date().toISOString()
      });
      
      setLicenses(licenses.map(license => 
        license.id === id ? { ...license, ...licenseData } : license
      ));
      setIsEditDialogOpen(false);
      setSelectedLicense(null);
    } catch (error) {
      console.error('Error updating license:', error);
    }
  };

  const handleGenerateNewKey = async (id: string) => {
    try {
      const newKey = generateLicenseKey(); // Implement this helper function
      await updateDoc(doc(db, 'licenses', id), {
        licenseKey: newKey,
        updatedAt: new Date().toISOString()
      });
      
      setLicenses(licenses.map(license => 
        license.id === id ? { ...license, licenseKey: newKey } : license
      ));
      setIsEditDialogOpen(false);
      setSelectedLicense(null);
    } catch (error) {
      console.error('Error generating new license key:', error);
    }
  };

  const handleEdit = (license: License) => {
    setSelectedLicense(license);
    setIsEditDialogOpen(true);
  };

  const filteredLicenses = licenses.filter(license => 
    license?.pluginName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    license?.domain?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    license?.userEmail?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    license?.licenseKey?.toLowerCase().includes(searchTerm.toLowerCase())
  );


  return (
    <div className="page-container">
      <Navbar />
      <div className="content-container">
        <div className="flex justify-between items-center mb-6">
          <h1 className="page-title">
            Licensz kezelés
          </h1>
          <button
            onClick={() => setIsAddDialogOpen(true)}
            className="btn-primary flex items-center space-x-2"
          >
            <AddIcon className="w-5 h-5" />
            <span>Új Licensz</span>
          </button>
        </div>
        
        <div className="mb-6">
          <div className="relative">
            <input
              type="text"
              className="input-field w-full pl-10"
              placeholder="Keresés plugin, domain, email vagy licensz kulcs alapján..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          </div>
        </div>
        
        <div className="data-table-container">
          <div className="overflow-x-auto">
            <table className="data-table">
              <thead className="bg-gray-50">
                <tr>
                  <th className="table-header">Plugin</th>
                  <th className="table-header">Domain</th>
                  <th className="table-header">Licensz kulcs</th>
                  <th className="table-header">Státusz</th>
                  <th className="table-header">Lejárat</th>
                  <th className="table-header">Felhasználó</th>
                  <th className="table-header">Műveletek</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredLicenses.map((license) => (
                  <tr 
                    key={license.id}
                    className={`table-row-hover ${
                      new Date(license.expiresAt) < new Date() 
                        ? 'bg-yellow-50'
                        : ''
                    }`}
                  >
                    <td className="table-cell">{license.pluginName}</td>
                    <td className="table-cell">{license.domain}</td>
                    <td className="table-cell font-mono">{license.licenseKey}</td>
                    <td className="table-cell">
                      <span className={`status-badge ${
                        new Date(license.expiresAt) >= new Date() 
                          ? 'status-badge-success' 
                          : 'status-badge-error'
                      }`}>
                        {new Date(license.expiresAt) >= new Date() ? 'Érvényes' : 'Lejárt'}
                      </span>
                    </td>
                    <td className="table-cell">
                      {new Date(license.expiresAt).toLocaleString('hu-HU')}
                    </td>
                    <td className="table-cell">{license.userEmail}</td>
                    <td className="table-cell">
                      <div className="flex space-x-2">
                        <button 
                          onClick={() => handleEdit(license)}
                          className="icon-button text-blue-600 hover:text-blue-800"
                        >
                          <EditIcon className="w-5 h-5" />
                        </button>
                        <button 
                          onClick={() => handleDeleteLicense(license.id)}
                          className="icon-button text-red-600 hover:text-red-800"
                        >
                          <DeleteIcon className="w-5 h-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      {isAddDialogOpen && (
        <AddLicenseDialog
          open={isAddDialogOpen}
          onClose={() => setIsAddDialogOpen(false)}
          onSuccess={handleAddLicense}
        />
      )}
      
      {isEditDialogOpen && selectedLicense && (
        <EditLicenseDialog
          open={isEditDialogOpen}
          onClose={() => {
            setIsEditDialogOpen(false);
            setSelectedLicense(null);
          }}
          onSave={handleEditLicense}
          onGenerateNewKey={handleGenerateNewKey}
          license={selectedLicense}
        />
      )}
    </div>
  );
};

export default LicenseManager; 