import React, { useState } from 'react';
import { Plugin } from '../types/plugin';
import { db } from '../firebaseConfig';
import { useAuth } from '../contexts/AuthContext';
import { collection, addDoc } from 'firebase/firestore';

interface BuyLicenseDialogProps {
  plugin: Plugin | null;
  isOpen: boolean;
  onClose: () => void;
}

const BuyLicenseDialog: React.FC<BuyLicenseDialogProps> = ({ plugin, isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  const handlePurchase = async () => {
    if (!plugin || !currentUser) return;
    
    setLoading(true);
    try {
      const purchaseRef = await addDoc(collection(db, 'pendingPurchases'), {
        pluginId: plugin.id,
        userId: currentUser.uid,
        userEmail: currentUser.email,
        createdAt: new Date(),
        status: 'pending',
        amount: plugin.price
      });

      const url = new URL(plugin.subscriptionLink);
      url.searchParams.append('client_reference_id', purchaseRef.id);
      url.searchParams.append('prefilled_email', currentUser.email || '');
      
      window.location.href = url.toString();
    } catch (error) {
      console.error('Payment error:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        {/* Háttér overlay */}
        <div 
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity backdrop-blur-sm" 
          onClick={onClose}
        />

        {/* Központosító trükk */}
        <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
          &#8203;
        </span>

        {/* Dialog panel */}
        <div className="relative inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
          {/* Close button */}
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 focus:outline-none"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          {/* Content */}
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg 
                  className="h-6 w-6 text-blue-600" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" 
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Plugin vásárlása
                </h3>
                <div className="mt-2">
                  <p className="text-xl font-semibold text-gray-900">
                    {plugin?.name}
                  </p>
                  <p className="mt-2 text-2xl font-bold text-blue-600">
                    {plugin?.price?.toLocaleString()} Ft
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="bg-gray-50 px-4 py-3 sm:px-6 flex justify-end">
            <button
              type="button"
              onClick={handlePurchase}
              disabled={loading}
              className="inline-flex items-center justify-center rounded-lg border-2 border-[#635BFF] bg-white px-6 py-3 text-base font-medium text-[#635BFF] shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#635BFF] focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
            >
              {loading ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-[#635BFF]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Feldolgozás...
                </span>
              ) : (
                <span className="flex flex-col items-center">
                  <span className="text-lg">Fizetés</span>
                  <img 
                    src="https://images.stripeassets.com/fzn2n1nzq965/4M6d6BSWzlgsrJx8rdZb0I/733f37ef69b5ca1d3d33e127184f4ce4/Powered_by_Stripe.svg"
                    alt="Powered by Stripe"
                    className="h-5 mt-1"
                  />
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyLicenseDialog; 