import React, { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Navbar from './Navbar';
import AddPluginDialog from './AddPluginDialog';
import { db, storage } from '../firebaseConfig';
import { collection, addDoc, getDocs, query, orderBy, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import EditPluginDialog from './EditPluginDialog';
import { Plugin } from '../types/plugin';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const PluginManager: React.FC = () => {
  const [plugins, setPlugins] = useState<Plugin[]>([]);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [selectedPlugin, setSelectedPlugin] = useState<Plugin | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const fetchPlugins = async () => {
      try {
        const pluginsRef = collection(db, 'plugins');
        const q = query(pluginsRef, orderBy('lastUpdated', 'desc'));
        const querySnapshot = await getDocs(q);
        
        const pluginsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Plugin[];
        
        setPlugins(pluginsData);
      } catch (error) {
        console.error('Error fetching plugins:', error);
      }
    };

    fetchPlugins();
  }, []);

  const handleStatusToggle = async (id: string) => {
    try {
      const pluginRef = doc(db, 'plugins', id);
      const plugin = plugins.find(p => p.id === id);
      if (!plugin) return;

      const newStatus = plugin.status === 'active' ? 'inactive' : 'active';
      await updateDoc(pluginRef, {
        status: newStatus
      });

      setPlugins(plugins.map(plugin => 
        plugin.id === id 
          ? { ...plugin, status: newStatus }
          : plugin
      ));
    } catch (error) {
      console.error('Error updating plugin status:', error);
    }
  };

  const handleAddPlugin = async (pluginData: Omit<Plugin, 'id'>) => {
    try {
      const docRef = await addDoc(collection(db, 'plugins'), pluginData);
      setPlugins([...plugins, { id: docRef.id, ...pluginData }]);
    } catch (error) {
      console.error('Error adding plugin:', error);
    }
  };

  const handleDeletePlugin = async (id: string) => {
    if (!window.confirm('Biztosan törölni szeretné ezt a plugint?')) return;

    try {
      await deleteDoc(doc(db, 'plugins', id));
      setPlugins(plugins.filter(plugin => plugin.id !== id));
    } catch (error) {
      console.error('Error deleting plugin:', error);
    }
  };

  const handleEditPlugin = async (id: string, pluginData: Partial<Plugin> & { file?: File }) => {
    try {
      setIsUploading(true);
      const pluginRef = doc(db, 'plugins', id);
      
      if (pluginData.file instanceof File) {
        const file = pluginData.file;
        const storageRef = ref(storage, `plugins/${file.name}`);
        
        await uploadBytes(storageRef, file);
        
        const downloadURL = await getDownloadURL(storageRef);
        
        pluginData.fileUrl = downloadURL;
        pluginData.fileName = file.name;
        delete pluginData.file;
      }

      await updateDoc(pluginRef, {
        ...pluginData,
        lastUpdated: new Date().toISOString()
      });

      setPlugins(plugins.map(plugin => 
        plugin.id === id 
          ? { ...plugin, ...pluginData }
          : plugin
      ));

      setIsEditDialogOpen(false);
    } catch (error) {
      console.error('Error updating plugin:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleSelectPlugin = (plugin: Plugin) => {
    setSelectedPlugin(plugin);
    setIsEditDialogOpen(true);
  };

  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 mt-8 mb-8">
        <div className="flex justify-between mb-6">
          <h1 className="text-2xl font-bold">
            WordPress Plugin-ok kezelése
          </h1>
          <button
            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md flex items-center gap-2"
            onClick={() => setIsAddDialogOpen(true)}
          >
            <AddIcon className="w-5 h-5" />
            Új Plugin
          </button>
        </div>
        
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Név</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Verzió</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Státusz</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ár</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Utolsó frissítés</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Műveletek</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {plugins.map((plugin) => (
                <tr key={plugin.id}>
                  <td className="px-6 py-4">
                    <div className="text-sm font-medium text-gray-900">{plugin.name}</div>
                    <div className="text-sm text-gray-500">{plugin.description}</div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">{plugin.version}</div>
                    {plugin.fileUrl && (
                      <div className="text-xs text-gray-500">
                        <a 
                          href={plugin.fileUrl} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:text-blue-800"
                        >
                          Download
                        </a>
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() => handleStatusToggle(plugin.id)}
                      className={`px-3 py-1 rounded-full text-sm font-medium
                        ${plugin.status === 'active' 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-gray-100 text-gray-800'}`}
                    >
                      {plugin.status === 'active' ? 'Aktív' : 'Inaktív'}
                    </button>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">{plugin.price?.toLocaleString()} Ft</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{plugin.lastUpdated}</td>
                  <td className="px-6 py-4 text-sm font-medium">
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleSelectPlugin(plugin)}
                        className="text-blue-600 hover:text-blue-900"
                      >
                        <EditIcon className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => handleDeletePlugin(plugin.id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        <DeleteIcon className="w-5 h-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <AddPluginDialog
          open={isAddDialogOpen}
          onClose={() => setIsAddDialogOpen(false)}
          onAdd={handleAddPlugin}
        />
        <EditPluginDialog
          open={isEditDialogOpen}
          onClose={() => setIsEditDialogOpen(false)}
          onSave={handleEditPlugin}
          plugin={selectedPlugin}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
        />
      </div>
    </>
  );
};

export default PluginManager; 