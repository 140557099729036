import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { License } from '../types/license';
import { useAuth } from '../contexts/AuthContext';
import Navbar from './Navbar';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

const MyLicenses: React.FC = () => {
  const [licenses, setLicenses] = useState<License[]>([]);
  const { currentUser } = useAuth();
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editDomain, setEditDomain] = useState('');

  useEffect(() => {
    const fetchLicenses = async () => {
      if (!currentUser?.uid) return;

      try {
        const licensesRef = collection(db, 'licenses');
        const q = query(licensesRef, where('userId', '==', currentUser.uid));
        const snapshot = await getDocs(q);
        
        const licensesData = await Promise.all(snapshot.docs.map(async (docSnapshot) => {
          const licenseData = docSnapshot.data();
          // Fetch plugin details
          const pluginRef = doc(db, 'plugins', licenseData.pluginId);
          const pluginDoc = await getDoc(pluginRef);
          const pluginData = pluginDoc.data();
          
          return {
            id: docSnapshot.id,
            ...licenseData,
            pluginName: (pluginData as { name?: string })?.name || 'Unknown Plugin'
          };
        })) as License[];
        
        setLicenses(licensesData);
      } catch (error) {
        console.error('Error fetching licenses:', error);
      }
    };

    fetchLicenses();
  }, [currentUser]);

  const handleSaveDomain = async (licenseId: string) => {
    try {
      const licenseRef = doc(db, 'licenses', licenseId);
      await updateDoc(licenseRef, { domain: editDomain });
      setLicenses(licenses.map(license => 
        license.id === licenseId ? { ...license, domain: editDomain } : license
      ));
      setEditingId(null);
    } catch (error) {
      console.error('Error updating domain:', error);
    }
  };

  return (
    <div className="page-container">
      <Navbar />
      <div className="content-container">
        <h1 className="page-title">
          Licenszeim
        </h1>
        
        <div className="data-table-container">
          <div className="overflow-x-auto">
            <table className="data-table">
              <thead className="bg-gray-50">
                <tr>
                  <th className="table-header">Plugin</th>
                  <th className="table-header">Domain</th>
                  <th className="table-header">Licensz kulcs</th>
                  <th className="table-header">Státusz</th>
                  <th className="table-header">Lejárat</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {licenses.map((license) => (
                  <tr key={license.id} className="table-row-hover">
                    <td className="table-cell">{license.pluginName}</td>
                    <td className="table-cell">
                      {editingId === license.id ? (
                        <div className="flex items-center space-x-2">
                          <input
                            type="text"
                            className="input-field"
                            value={editDomain}
                            onChange={(e) => setEditDomain(e.target.value)}
                            placeholder="Add domain..."
                          />
                          <button 
                            onClick={() => handleSaveDomain(license.id)}
                            className="icon-button"
                          >
                            <SaveIcon className="w-5 h-5 text-blue-600" />
                          </button>
                        </div>
                      ) : (
                        <div className="flex items-center space-x-2">
                          <span>{license.domain || 'Nincs beállítva'}</span>
                          <button 
                            onClick={() => {
                              setEditingId(license.id);
                              setEditDomain(license.domain || '');
                            }}
                            className="icon-button"
                          >
                            <EditIcon className="w-5 h-5 text-gray-600" />
                          </button>
                        </div>
                      )}
                    </td>
                    <td className="table-cell">{license.licenseKey}</td>
                    <td className="table-cell">
                      <span className={`status-badge ${
                        new Date(license.expiresAt) >= new Date() 
                          ? 'status-badge-success' 
                          : 'status-badge-error'
                      }`}>
                        {new Date(license.expiresAt) >= new Date() ? 'Érvényes' : 'Lejárt'}
                      </span>
                    </td>
                    <td className="table-cell">
                      {new Date(license.expiresAt).toLocaleDateString('hu-HU')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyLicenses; 