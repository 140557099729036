import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { auth } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';

const Navbar: React.FC = () => {
  const { currentUser } = useAuth();
  console.log('User data:', currentUser);
  console.log('Current user photo URL:', currentUser?.photoURL);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    handleClose();
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <nav className="bg-white border-b shadow-sm">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16 items-center">
          <div 
            onClick={() => handleNavigation('/')} 
            className="text-xl flex items-center gap-2 cursor-pointer hover:opacity-80"
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 24 24" 
              fill="currentColor" 
              className="w-8 h-8 text-indigo-600"
            >
              <path d="M5.223 2.25c-.497 0-.974.198-1.325.55l-1.3 1.298A3.75 3.75 0 007.5 9.75c.627.47 1.406.75 2.25.75.844 0 1.624-.28 2.25-.75.626.47 1.406.75 2.25.75.844 0 1.623-.28 2.25-.75a3.75 3.75 0 004.902-5.652l-1.3-1.299a1.875 1.875 0 00-1.325-.549H5.223z" />
              <path fillRule="evenodd" d="M3 20.25v-8.755c1.42.674 3.08.673 4.5 0A5.234 5.234 0 009.75 12c.804 0 1.568-.182 2.25-.506a5.234 5.234 0 002.25.506c.804 0 1.567-.182 2.25-.506 1.42.674 3.08.675 4.5.001v8.755h.75a.75.75 0 010 1.5H2.25a.75.75 0 010-1.5H3zm3-6a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v3a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75v-3zm8.25-.75a.75.75 0 00-.75.75v5.25c0 .414.336.75.75.75h3a.75.75 0 00.75-.75v-5.25a.75.75 0 00-.75-.75h-3z" clipRule="evenodd" />
            </svg>
            <span className="font-semibold text-gray-900">WP Market</span>
          </div>
          
          {currentUser && (
            <div className="flex items-center gap-6">
              <div className="flex items-center gap-4">
                {currentUser.isAdmin ? (
                  <>
                    <button 
                      onClick={() => handleNavigation('/users')}
                      className="px-4 py-2 rounded-md text-indigo-600 hover:bg-indigo-50 border border-indigo-200 transition-colors duration-200"
                    >
                      Felhasználók
                    </button>
                    <button 
                      onClick={() => handleNavigation('/plugins')}
                      className="px-4 py-2 rounded-md text-indigo-600 hover:bg-indigo-50 border border-indigo-200 transition-colors duration-200"
                    >
                      Plugin-ok
                    </button>
                    <button 
                      onClick={() => handleNavigation('/licenses')}
                      className="px-4 py-2 rounded-md text-indigo-600 hover:bg-indigo-50 border border-indigo-200 transition-colors duration-200"
                    >
                      Licenszek
                    </button>
                  </>
                ) : (
                  <button 
                    onClick={() => handleNavigation('/store')}
                    className="px-4 py-2 rounded-md text-indigo-600 hover:bg-indigo-50 border border-indigo-200 transition-colors duration-200"
                  >
                    Plugin Áruház
                  </button>
                )}
              </div>

              <div className="relative z-40">
                <button
                  onClick={handleMenu}
                  className="flex items-center"
                >
                  {currentUser?.photoURL ? (
                    <img
                      className="h-8 w-8 rounded-full"
                      src={currentUser.photoURL}
                      alt="Profile"
                      referrerPolicy="no-referrer"
                    />
                  ) : (
                    <div className="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center">
                      {currentUser?.displayName?.[0]?.toUpperCase() || currentUser?.email?.[0]?.toUpperCase() || '?'}
                    </div>
                  )}
                </button>
                
                {Boolean(anchorEl) && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
                    <div className="px-4 py-2">
                      <p className="text-sm font-medium">{currentUser.displayName}</p>
                      <p className="text-sm text-gray-500">{currentUser.email}</p>
                    </div>
                    <hr />
                    <button 
                      onClick={() => handleNavigation('/my-licenses')}
                      className="w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                    >
                      Licenszeim
                    </button>
                    <button 
                      onClick={() => handleNavigation('/purchases')}
                      className="w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                    >
                      Vásárlásaim
                    </button>
                    <button 
                      onClick={handleLogout}
                      className="w-full text-left px-4 py-2 text-sm hover:bg-gray-100 text-red-600"
                    >
                      Kijelentkezés
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 