import React, { useState, useEffect } from 'react';
import { License } from '../types/license';
import { Plugin } from '../types/plugin';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';

interface EditLicenseDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (id: string, licenseData: Partial<License>) => Promise<void>;
  onGenerateNewKey: (id: string) => Promise<void>;
  license: License | null;
}

const EditLicenseDialog: React.FC<EditLicenseDialogProps> = ({ 
  open, 
  onClose, 
  onSave, 
  onGenerateNewKey,
  license 
}) => {
  const [plugins, setPlugins] = useState<Plugin[]>([]);
  const [formData, setFormData] = useState({
    pluginId: '',
    domain: '',
    expiresAt: '',
    userEmail: ''
  });

  useEffect(() => {
    const fetchPlugins = async () => {
      const pluginsRef = collection(db, 'plugins');
      const snapshot = await getDocs(pluginsRef);
      const pluginsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as Plugin[];
      setPlugins(pluginsData);
    };

    fetchPlugins();
  }, []);

  useEffect(() => {
    if (license) {
      setFormData({
        pluginId: license.pluginId || '',
        domain: license.domain || '',
        expiresAt: license.expiresAt ? license.expiresAt.split('T')[0] : '',
        userEmail: license.userEmail || ''
      });
    }
  }, [license]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (license) {
      await onSave(license.id, formData);
      onClose();
    }
  };

  const handleGenerateNewKey = async () => {
    if (license && window.confirm('Biztosan új kulcsot szeretnél generálni? A régi kulcs érvénytelenné válik.')) {
      await onGenerateNewKey(license.id);
      onClose();
    }
  };

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4">
        <div className="fixed inset-0 bg-black bg-opacity-25" onClick={onClose} />
        
        <div className="relative bg-white rounded-lg shadow-xl max-w-md w-full">
          <div className="px-6 py-4 border-b">
            <h2 className="text-xl font-semibold text-gray-900">Licensz szerkesztése</h2>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="px-6 py-4 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Plugin
                </label>
                <select
                  value={formData.pluginId}
                  onChange={(e) => setFormData({ ...formData, pluginId: e.target.value })}
                  required
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="">Válassz plugin-t</option>
                  {plugins.map((plugin) => (
                    <option key={plugin.id} value={plugin.id}>
                      {plugin.name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Domain
                </label>
                <input
                  type="text"
                  value={formData.domain}
                  onChange={(e) => setFormData({ ...formData, domain: e.target.value })}
                  required
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Felhasználó email
                </label>
                <input
                  type="email"
                  value={formData.userEmail}
                  onChange={(e) => setFormData({ ...formData, userEmail: e.target.value })}
                  required
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Lejárat dátuma
                </label>
                <input
                  type="date"
                  value={formData.expiresAt}
                  onChange={(e) => setFormData({ ...formData, expiresAt: e.target.value })}
                  required
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              {license && (
                <div className="pt-2">
                  <button
                    type="button"
                    onClick={handleGenerateNewKey}
                    className="w-full px-4 py-2 border border-orange-500 text-orange-600 rounded-md hover:bg-orange-50"
                  >
                    Új licensz kulcs generálása
                  </button>
                </div>
              )}
            </div>

            <div className="px-6 py-4 border-t bg-gray-50 flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 rounded-md text-gray-700 hover:bg-gray-100"
              >
                Mégsem
              </button>
              <button
                type="submit"
                className="px-4 py-2 rounded-md bg-indigo-600 text-white hover:bg-indigo-700"
              >
                Mentés
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditLicenseDialog; 