import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { Plugin } from '../types/plugin';
import Navbar from './Navbar';
import BuyLicenseDialog from '../components/BuyLicenseDialog';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const formatDate = (date: any) => {
  if (!date) return 'N/A';
  return new Date(date).toLocaleDateString('hu-HU');
};

const Store: React.FC = () => {
  const [plugins, setPlugins] = useState<Plugin[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedPlugin, setSelectedPlugin] = useState<Plugin | null>(null);
  const [isBuyDialogOpen, setIsBuyDialogOpen] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlugins = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // Egyszerű lekérdezés, csak az aktív pluginokat kérjük le
        const pluginsRef = collection(db, 'plugins');
        const q = query(pluginsRef, where('status', '==', 'active'));
        const snapshot = await getDocs(q);
        
        const pluginsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Plugin[];

        console.log('Fetched plugins:', pluginsData); // Debug log
        setPlugins(pluginsData);
      } catch (error) {
        console.error('Error fetching plugins:', error);
        setError('Hiba történt a pluginok betöltése közben');
      } finally {
        setLoading(false);
      }
    };

    fetchPlugins();
  }, []); // Üres dependency array, csak egyszer fut le

  const handleBuyClick = (plugin: Plugin) => {
    if (!currentUser) {
      navigate('/login', { state: { from: '/store', plugin: plugin.id } });
      return;
    }
    setSelectedPlugin(plugin);
    setIsBuyDialogOpen(true);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Navbar />
        <div className="container mx-auto px-4 py-8 max-w-7xl">
          <div className="text-center">
            <p className="text-gray-500">Pluginok betöltése...</p>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Navbar />
        <div className="container mx-auto px-4 py-8 max-w-7xl">
          <div className="text-center text-red-600">
            <p>{error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="container mx-auto px-4 py-8 max-w-7xl">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Plugin Áruház
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Fedezd fel prémium WordPress pluginjeinket. Minden plugin magyar fejlesztőktől, magyar támogatással.
          </p>
        </div>
        
        {plugins.length === 0 ? (
          <div className="text-center py-12">
            <p className="text-gray-500 text-lg">Jelenleg nincs elérhető plugin.</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {plugins.map((plugin) => (
              <div 
                key={plugin.id} 
                className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-200 overflow-hidden border border-gray-100"
              >
                <div className="relative aspect-w-16 aspect-h-9">
                  {plugin.screenshots && plugin.screenshots.length > 0 ? (
                    <img
                      src={plugin.screenshots[0]}
                      alt={plugin.name}
                      className="w-full h-48 object-cover"
                    />
                  ) : (
                    <div className="w-full h-48 bg-gray-100 flex items-center justify-center">
                      <svg className="w-12 h-12 text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                      </svg>
                    </div>
                  )}
                  <div className="absolute top-2 right-2">
                    <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded">
                      v{plugin.version}
                    </span>
                  </div>
                </div>
                <div className="p-6">
                  <h2 className="text-xl font-semibold mb-2 text-gray-900">
                    {plugin.name}
                  </h2>
                  <p className="text-2xl font-bold text-blue-600 mb-4">
                    {plugin.price?.toLocaleString()} Ft
                  </p>
                  <div className="space-y-3 mb-6">
                    <p className="text-gray-600 line-clamp-3">
                      {plugin.description || 'Nincs leírás'}
                    </p>
                    <p className="text-sm text-gray-500">
                      Utoljára frissítve: {formatDate(plugin.lastUpdated)}
                    </p>
                    <div className="flex flex-wrap gap-2">
                      <span className="text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded">
                        {plugin.fileName}
                      </span>
                    </div>
                  </div>
                  <button 
                    onClick={() => handleBuyClick(plugin)}
                    className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg
                             hover:bg-blue-700 transition duration-200 ease-in-out
                             focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
                             flex items-center justify-center gap-2"
                  >
                    {!currentUser ? (
                      <>
                        <span>Bejelentkezés a vásárláshoz</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                      </>
                    ) : (
                      'Vásárlás'
                    )}
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      
      {currentUser && (
        <BuyLicenseDialog
          plugin={selectedPlugin}
          isOpen={isBuyDialogOpen}
          onClose={() => setIsBuyDialogOpen(false)}
        />
      )}
    </div>
  );
};

export default Store; 