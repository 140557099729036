import React, { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import Navbar from './Navbar';
import { useAuth } from '../contexts/AuthContext';
import { collection, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { User } from '../types/user';
import EditUserDialog from './EditUserDialog';

const UserManager: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const { currentUser } = useAuth();
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const snapshot = await getDocs(usersCollection);
        const usersData = snapshot.docs.map(doc => ({
          uid: doc.id,
          ...doc.data()
        })) as User[];
        setUsers(usersData);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleAdminToggle = async (uid: string) => {
    try {
      const userToUpdate = users.find(user => user.uid === uid);
      if (!userToUpdate) return;

      const userRef = doc(db, 'users', userToUpdate.uid);
      await updateDoc(userRef, {
        isAdmin: !userToUpdate.isAdmin
      });

      setUsers(users.map(user => 
        user.uid === uid 
          ? { ...user, isAdmin: !user.isAdmin }
          : user
      ));
    } catch (error) {
      console.error('Error toggling admin status:', error);
      alert('Hiba történt a jogosultság módosítása során!');
    }
  };

  const handleEditUser = async (userId: string, userData: Partial<User>) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, userData);
      
      setUsers(users.map(user => 
        user.uid === userId 
          ? { ...user, ...userData }
          : user
      ));
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleEdit = (user: User) => {
    setSelectedUser(user);
    setIsEditDialogOpen(true);
  };

  const handleDelete = async (userId: string) => {
    try {
      const userRef = doc(db, 'users', userId);
      await deleteDoc(userRef);
      setUsers(users.filter(user => user.uid !== userId));
    } catch (error) {
      console.error('Error deleting user:', error);
      alert('Hiba történt a felhasználó törlésekor!');
    }
  };

  const filteredUsers = users.filter(user => 
    user.displayName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 mt-8 mb-8">
        <div className="flex justify-between mb-6">
          <h1 className="text-2xl font-bold">
            Felhasználók kezelése
          </h1>
        </div>
        
        <div className="relative mb-6">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <SearchIcon className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white 
                     placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 
                     focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Keresés név vagy email alapján..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Név</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Jogosultság</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Regisztráció ideje</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Műveletek</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredUsers.map((user, index) => (
                <tr key={`${user.uid}-${index}`}>
                  <td className="px-6 py-4">
                    <div className="text-sm font-medium text-gray-900">{user.displayName}</div>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">{user.email}</td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() => handleAdminToggle(user.uid)}
                      className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium
                        ${user.isAdmin 
                          ? 'bg-blue-100 text-blue-800' 
                          : 'bg-gray-100 text-gray-800'}`}
                    >
                      {user.isAdmin ? 'Admin' : 'Felhasználó'}
                    </button>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {new Date(user.createdAt).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    <div className="flex space-x-2">
                      <button
                        className={`text-blue-600 hover:text-blue-900 disabled:opacity-50 disabled:cursor-not-allowed`}
                        disabled={user.uid === currentUser?.uid}
                        onClick={() => handleEdit(user)}
                      >
                        <EditIcon className="w-5 h-5" />
                      </button>
                      <button
                        className={`text-red-600 hover:text-red-900 disabled:opacity-50 disabled:cursor-not-allowed`}
                        disabled={user.uid === currentUser?.uid}
                        onClick={() => handleDelete(user.uid)}
                      >
                        <DeleteIcon className="w-5 h-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <EditUserDialog
          open={isEditDialogOpen}
          onClose={() => {
            setIsEditDialogOpen(false);
            setSelectedUser(null);
          }}
          onSave={handleEditUser}
          user={selectedUser}
        />
      </div>
    </>
  );
};

export default UserManager; 