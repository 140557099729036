import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where, orderBy, limit } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Navbar from './Navbar';
import { formatCurrency } from '../utils/formatters';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

interface DashboardStats {
  users: {
    total: number;
    newThisMonth: number;
    activeThisMonth: number;
  };
  sales: {
    totalRevenue: number;
    monthlyRevenue: number;
    totalTransactions: number;
    monthlyTransactions: number;
  };
  licenses: {
    total: number;
    active: number;
    expired: number;
  };
  plugins: {
    total: number;
    active: number;
  };
}

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const {currentUser} = useAuth();
  const [stats, setStats] = useState<DashboardStats>({
    users: { total: 0, newThisMonth: 0, activeThisMonth: 0 },
    sales: { totalRevenue: 0, monthlyRevenue: 0, totalTransactions: 0, monthlyTransactions: 0 },
    licenses: { total: 0, active: 0, expired: 0 },
    plugins: { total: 0, active: 0 }
  });
  const [recentTransactions, setRecentTransactions] = useState<any[]>([]);

  useEffect(() => {
    if (currentUser && !currentUser.isAdmin) {
      navigate('/store');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      const now = new Date();
      const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

      // Felhasználói statisztikák
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const newUsersSnapshot = await getDocs(query(
        collection(db, 'users'),
        where('createdAt', '>=', firstDayOfMonth)
      ));

      // Eladási statisztikák
      const salesSnapshot = await getDocs(collection(db, 'transactions'));
      const monthlySalesSnapshot = await getDocs(query(
        collection(db, 'transactions'),
        where('createdAt', '>=', firstDayOfMonth)
      ));

      // Licensz statisztikák
      const licensesSnapshot = await getDocs(collection(db, 'licenses'));
      const activeLicenses = licensesSnapshot.docs.filter(
        doc => new Date(doc.data().expiryDate) > now
      );

      // Plugin statisztikák
      const pluginsSnapshot = await getDocs(collection(db, 'plugins'));
      const activePlugins = pluginsSnapshot.docs.filter(
        doc => doc.data().status === 'active'
      );

      // Legutóbbi tranzakciók
      const recentTransactionsQuery = query(
        collection(db, 'transactions'),
        orderBy('createdAt', 'desc'),
        limit(5)
      );
      const recentTransactionsSnapshot = await getDocs(recentTransactionsQuery);

      // Statisztikák beállítása
      setStats({
        users: {
          total: usersSnapshot.size,
          newThisMonth: newUsersSnapshot.size,
          activeThisMonth: newUsersSnapshot.size // Ez lehet más logika alapján is
        },
        sales: {
          totalRevenue: salesSnapshot.docs.reduce((sum, doc) => sum + doc.data().amount, 0),
          monthlyRevenue: monthlySalesSnapshot.docs.reduce((sum, doc) => sum + doc.data().amount, 0),
          totalTransactions: salesSnapshot.size,
          monthlyTransactions: monthlySalesSnapshot.size
        },
        licenses: {
          total: licensesSnapshot.size,
          active: activeLicenses.length,
          expired: licensesSnapshot.size - activeLicenses.length
        },
        plugins: {
          total: pluginsSnapshot.size,
          active: activePlugins.length
        }
      });

      setRecentTransactions(recentTransactionsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })));
    };

    if (currentUser?.isAdmin) {
      fetchDashboardData();
    }
  }, [currentUser]);

  if (!currentUser?.isAdmin) {
    return null;
  }

  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 py-8">
        <div className="grid gap-6">
          {/* Header Section */}
          <div className="bg-gradient-to-r from-blue-600 to-blue-700 text-white rounded-lg p-6 shadow-lg">
            <h1 className="text-3xl font-bold mb-2">Webshop Áttekintés</h1>
            <p className="text-blue-100">Valós idejű statisztikák és eladások</p>
          </div>

          {/* Main Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {/* Bevétel */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <p className="text-gray-500 text-sm mb-2">Havi Bevétel</p>
              <p className="text-3xl font-bold text-green-600">
                {formatCurrency(stats.sales.monthlyRevenue)}
              </p>
              <p className="text-sm text-gray-500 mt-1">
                {stats.sales.monthlyTransactions} tranzakció
              </p>
            </div>

            {/* Felhasználók */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <p className="text-gray-500 text-sm mb-2">Felhasználók</p>
              <p className="text-3xl font-bold text-blue-600">{stats.users.total}</p>
              <p className="text-sm text-gray-500 mt-1">
                +{stats.users.newThisMonth} új ebben a hónapban
              </p>
            </div>

            {/* Licenszek */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <p className="text-gray-500 text-sm mb-2">Aktív Licenszek</p>
              <p className="text-3xl font-bold text-purple-600">{stats.licenses.active}</p>
              <p className="text-sm text-gray-500 mt-1">
                {((stats.licenses.active / stats.licenses.total) * 100).toFixed(1)}% aktív
              </p>
            </div>

            {/* Pluginok */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <p className="text-gray-500 text-sm mb-2">Aktív Pluginok</p>
              <p className="text-3xl font-bold text-orange-600">{stats.plugins.active}</p>
              <p className="text-sm text-gray-500 mt-1">
                {stats.plugins.total} pluginból
              </p>
            </div>
          </div>

          {/* Detailed Stats Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {/* Sales Overview */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl font-semibold mb-4">Eladási Áttekintés</h2>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-gray-500 text-sm">Összes Bevétel</p>
                  <p className="text-xl font-bold text-gray-900">
                    {formatCurrency(stats.sales.totalRevenue)}
                  </p>
                </div>
                <div>
                  <p className="text-gray-500 text-sm">Összes Tranzakció</p>
                  <p className="text-xl font-bold text-gray-900">
                    {stats.sales.totalTransactions}
                  </p>
                </div>
              </div>
            </div>

            {/* Recent Transactions */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl font-semibold mb-4">Legutóbbi Tranzakciók</h2>
              <div className="space-y-4">
                {recentTransactions.map(transaction => (
                  <div key={transaction.id} className="flex justify-between items-center border-b pb-2">
                    <div>
                      <p className="font-medium">{transaction.pluginName}</p>
                      <p className="text-sm text-gray-500">
                        {new Date(transaction.createdAt).toLocaleDateString()}
                      </p>
                    </div>
                    <p className="font-medium text-green-600">
                      {formatCurrency(transaction.amount)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard; 