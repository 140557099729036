import React, { useState, useEffect, useRef } from 'react';
import { Plugin } from '../types/plugin';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage } from '../firebaseConfig';
import { useAuth } from '../contexts/AuthContext';

interface EditPluginDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (id: string, pluginData: Partial<Plugin> & { file?: File }) => Promise<void>;
  plugin: Plugin | null;
  isUploading: boolean;
  setIsUploading: (uploading: boolean) => void;
}

const EditPluginDialog: React.FC<EditPluginDialogProps> = ({ open, onClose, onSave, plugin, isUploading, setIsUploading }) => {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({
    name: '',
    version: '',
    description: '',
    price: 0,
    subscriptionLink: '',
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [screenshots, setScreenshots] = useState<File[]>([]);
  const [existingScreenshots, setExistingScreenshots] = useState<string[]>(plugin?.screenshots || []);
  const screenshotInputRef = useRef<HTMLInputElement>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (plugin) {
      setFormData({
        name: plugin.name,
        version: plugin.version,
        description: plugin.description,
        price: plugin.price,
        subscriptionLink: plugin.subscriptionLink || '',
      });
    }
  }, [plugin]);

  const handleUpload = async () => {
    if (!selectedFile || !currentUser) return '';
    
    setIsUploading(true);
    setUploadProgress(0);
    
    try {
        // Először töröljük a régi fájlt, ha létezik
        if (plugin?.fileUrl) {
            try {
                const oldFileRef = ref(storage, plugin.fileUrl);
                await deleteObject(oldFileRef);
                console.log('Old plugin file deleted successfully');
            } catch (error) {
                console.error('Error deleting old file:', error);
                // Nem szakítjuk meg a folyamatot, ha a törlés sikertelen
            }
        }
        
        // Új fájl feltöltése
        const originalFileName = plugin?.fileName || selectedFile.name;
        const storageRef = ref(storage, `plugins/${crypto.randomUUID()}-${originalFileName}`);
        const uploadTask = uploadBytesResumable(storageRef, selectedFile);
        
        return new Promise<string>((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress);
                },
                (error) => {
                    setIsUploading(false);
                    reject(error);
                },
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    setIsUploading(false);
                    resolve(downloadURL);
                }
            );
        });
    } catch (error) {
        setIsUploading(false);
        throw error;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const fileUrl = selectedFile ? await handleUpload() : plugin?.fileUrl ?? '';
      const newScreenshotUrls = await uploadScreenshots();
      
      onSave(plugin?.id || '', {
        ...formData,
        fileUrl,
        screenshots: [...existingScreenshots, ...newScreenshotUrls],
        lastUpdated: new Date().toISOString(),
      });
      onClose();
    } catch (error) {
      console.error('Error saving plugin:', error);
    }
  };

  const handleScreenshotSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files).filter(file => 
        file.type.startsWith('image/')
      );
      setScreenshots(prev => [...prev, ...files]);
    }
  };

  const handleDeleteExistingScreenshot = (urlToDelete: string) => {
    setExistingScreenshots(prev => prev.filter(url => url !== urlToDelete));
  };

  const uploadScreenshots = async () => {
    const urls = await Promise.all(
      screenshots.map(async (file) => {
        const storageRef = ref(storage, `plugin-screenshots/${crypto.randomUUID()}-${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        
        return new Promise<string>((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            null,
            (error) => reject(error),
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
            }
          );
        });
      })
    );
    return urls;
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.type !== 'application/zip' && !file.name.endsWith('.zip')) {
        alert('Please select a ZIP file');
        return;
      }
      setSelectedFile(file);
    }
  };

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4">
        <div className="fixed inset-0 bg-black bg-opacity-25" onClick={onClose} />
        
        <div className="relative bg-white rounded-lg shadow-xl max-w-md w-full">
          <div className="px-6 py-4 border-b">
            <h2 className="text-xl font-semibold text-gray-900">Edit Plugin</h2>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="px-6 py-4 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Név
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  required
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Verzió
                </label>
                <input
                  type="text"
                  value={formData.version}
                  onChange={(e) => setFormData({ ...formData, version: e.target.value })}
                  required
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Ár
                </label>
                <input
                  type="number"
                  value={formData.price}
                  onChange={(e) => setFormData({ ...formData, price: Number(e.target.value) })}
                  required
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Stripe Subscription Link
                </label>
                <input
                  type="text"
                  value={formData.subscriptionLink}
                  onChange={(e) => setFormData({ ...formData, subscriptionLink: e.target.value })}
                  required
                  placeholder="https://buy.stripe.com/..."
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Leírás
                </label>
                <textarea
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  rows={4}
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              <div>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  className="hidden"
                  ref={screenshotInputRef}
                  onChange={handleScreenshotSelect}
                />
                <button
                  type="button"
                  onClick={() => screenshotInputRef.current?.click()}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Képernyőképek feltöltése
                </button>
              </div>

              {/* Existing screenshots */}
              {existingScreenshots.length > 0 && (
                <div className="mt-4">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">Meglévő képernyőképek:</h3>
                  <div className="grid grid-cols-3 gap-2">
                    {existingScreenshots.map((url, index) => (
                      <div key={index} className="relative group">
                        <img 
                          src={url} 
                          alt={`Screenshot ${index + 1}`} 
                          className="w-full h-24 object-cover rounded-md"
                        />
                        <button
                          type="button"
                          onClick={() => handleDeleteExistingScreenshot(url)}
                          className="absolute top-1 right-1 p-1 bg-white rounded-full shadow-sm opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          <svg className="w-4 h-4 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* New screenshots */}
              {screenshots.length > 0 && (
                <div className="mt-4">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">Új képernyőképek:</h3>
                  <div className="space-y-2">
                    {screenshots.map((file, index) => (
                      <div key={index} className="flex items-center justify-between text-sm text-gray-600">
                        <span>{file.name}</span>
                        <button
                          type="button"
                          onClick={() => setScreenshots(prev => prev.filter((_, i) => i !== index))}
                          className="p-1 hover:text-red-600"
                        >
                          <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Plugin File Upload Section */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Plugin File (ZIP)
                </label>
                <div className="flex items-center space-x-4">
                  <input
                    type="file"
                    ref={fileInputRef}
                    accept=".zip"
                    onChange={handleFileSelect}
                    className="hidden"
                  />
                  <button
                    type="button"
                    onClick={() => fileInputRef.current?.click()}
                    className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  >
                    Choose File
                  </button>
                  {(selectedFile || plugin?.fileName) && (
                    <span className="text-sm text-gray-600">
                      {selectedFile?.name || plugin?.fileName}
                    </span>
                  )}
                </div>
                
                {/* Upload Progress */}
                {isUploading && (
                  <div className="mt-2">
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                      <div 
                        className="bg-indigo-600 h-2.5 rounded-full transition-all duration-300"
                        style={{ width: `${uploadProgress}%` }}
                      />
                    </div>
                    <span className="text-sm text-gray-600 mt-1">
                      Uploading: {Math.round(uploadProgress)}%
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="px-6 py-4 border-t bg-gray-50 flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                disabled={isUploading}
                className="px-4 py-2 rounded-md text-gray-700 hover:bg-gray-100"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isUploading}
                className={`px-4 py-2 rounded-md bg-indigo-600 text-white hover:bg-indigo-700 
                  ${isUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {isUploading ? 'Uploading...' : 'Save'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditPluginDialog; 